import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';
import './Options.css';
import './raffle_style_v1.css';

import OptionsList from './OptionsList';

var ItemList = {
  "options": [
    {
      "title" : "RCM Certificate Program",
      "description": "Exams, Music Books, Curriculum, Medals and Awards, Recitals",
      "selected": false,
    },
    {
      "title" : "Teacher Resources",
      "description": "Webinars, Workshops, Teacher Portal ",
      "selected": false,
    },
    {
      "title" : "Professional Development",
      "description": "Courses",
      "selected": false,
    },
    {
      "title" : "Digital Learning Resources for teachers and students",
      "description": "Theory Apps, Ear Training Tools, Theory and History Courses",
      "selected": false,
    }
  ]
}

export default class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: false,
    };
  }

  showLog = text => {
    //console.log(text);
  }

  componentDidMount() {
    this.showLog('[componentDidMount] in Options.js')
    this.props.mainView.updateOptions(ItemList.options);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.mainView.setState({
      [name]: value,
    });
  }

  resetOptions = () => {
    this.props.mainView.updateOptions(ItemList.options);
  }

  optionsToggle(event) {
    this.showLog('[optionsToggle] options: ' + this.state.options);
    this.setState({
      options: (this.state.options) ? false : true,
    });
  }

  render() {
    return (
      <div className='Options-container'>
        <Container fluid='true'>
        <Row>
          <Col >
            <div className='options-text'> Stay up to date with the latest news from The Royal Conservatory Certificate Program by picking which topics interest you. </div>
          </Col>
        </Row>
          <Row>
            <Col className='option-title'>
              <div className={(this.state.options ? "toggle-active" : "toggle-inactive")} onClick={(event)=>this.optionsToggle(event)}> VIEW OUR PRODUCTS </div>
            </Col>
          </Row>
          <Row>
            <Col className='options-holder'>
              {(this.state.options) ? <OptionsList ref={ optionslist => {this.optionslist = optionslist; }} optionsView={this} mainView={this.props.mainView} /> : null }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
