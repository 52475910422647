import React, { Component } from 'react';
import {BrowserRouter,Switch,Route,Link} from "react-router-dom";
import './App.css';

import Home from './components/Home';
import Form from './components/Form';
import LogIn from './components/LogIn';

import swal from 'sweetalert';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import logo from './assets/RCMCertifiedProgram_Generic_WHITE.svg';
import Cookies from 'js-cookie';
/*
import { withAuthenticator } from 'aws-amplify-react';
Amplify.configure(aws_exports);
*/


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currEnvironment: "prod",
      events: [],
      DevEventListAPI: "https://snorxng5la.execute-api.us-east-1.amazonaws.com/service/dev",
      ProdEventListAPI: "https://snorxng5la.execute-api.us-east-1.amazonaws.com/service/prod",

      DevEventWebformAPI: "https://7yx0a869wf.execute-api.us-east-1.amazonaws.com/service/dev",
      ProdEventWebformAPI: "https://7yx0a869wf.execute-api.us-east-1.amazonaws.com/service/prod",

      currEventData: Cookies.get("currEventData"),
      currEventId: "",
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  componentDidMount() {
    document.title = 'RCM Event';
  }

  showLog = text => {
    //console.log(text);
  }

  setAuthStatus = authenticated => {
     this.showLog("[setAuthStatus] " + authenticated);
    this.setState({ isAuthenticated: authenticated }, () => {
       this.showLog("isAuthenticated: " + this.state.isAuthenticated);
    });
  }

  setCurrEventID = id => {
     this.showLog("[setCurrEventID] " + id);
    this.setState({ currEventId: id }, () => {
       this.showLog("currEventId: " + this.state.currEventId);
    });
  }

  getEventsList = () => {
    this.showLog("[getEventsList] App");

    this.setState({
      errors: {
        apierrors: "retrieving the events list..."
      }
    });
    // get all the events from the database

    const ApiUrl = (this.state.currEnvironment == "prod") ? this.state.ProdEventListAPI : this.state.DevEventListAPI;
    this.showLog("ApiUrl: " + ApiUrl)

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //this.showLog("responseJson: " + JSON.stringify(responseJson, null, 4));
      const body = responseJson["body"];
      //this.showLog("body: " + JSON.stringify(body, null, 4));
      const items = body.Items;
      this.showLog("items: " + JSON.stringify(items, null, 4));

      this.setState({
        events: items,
        errors: {
          apierrors: ""
        }


      }, () => {
        //this.updateEventsList();
        this.showLog("events set");
        return;
      });


      if (items === undefined) {
        this.setState({
          errors: {
            apierrors: "can't find events."
          }
        });
        return;
      }

    })
    .catch((error) => {
      this.showLog("ERROR [getEventsList]: " + JSON.stringify(error));
      this.setState({
        errors: {
          apierrors: JSON.stringify(error)
        }
      });
      return;
    });
  }

  setCurrEventData = data => {
     this.showLog("[setCurrEventData] ");
     Cookies.set('currEventData', data);

     this.showLog("Cookie set currEventData: " + Cookies.get('currEventData'));
     const currdata = JSON.parse(Cookies.get('currEventData'));
    this.setState({ currEventData: currdata }, () => {
       this.showLog("currEventData: " + JSON.stringify(this.state.currEventData, null, 4));
    });
  }


/*
  goSubmit() {
    this.showLog('[goSubmit] options: ' + this.state.options);

    this.prepareData(this.state.options)
  }
*/

/*
  prepareData(json) {
    this.showLog('[prepareData]')


    var options = []
    for (var i = 0; i<json.length; i++) {
      let product = json[i];

      if (product.selected) {
        this.showLog('adding product: ' + product.title);
        options.push(product.title);
      }
    }
    this.validateEntries(options);
  }

  */

/*
  emailIsValid (email) {
    this.showLog("[emailIsValid] email: " + email);
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
*/

/*
  validateEntries(options) {
    this.showLog('[validateEntries]' );
    if (this.state.name === '') {
      this.showAlert("Oops", "Please enter your name.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.email === '') {
      this.showAlert("Oops", "Please enter your email address.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.email !== '') {
      if (this.emailIsValid(this.state.email) === false) {
        this.showAlert("Oops", "Please enter your valid email address.", "warning");
        this.submitview.setState({
          submitted:false
        });
        return;
      } else {
        if (this.compareEmails(this.state.email) === false) {
          this.showAlert("Oops", "You have already entered this email address.", "warning");
          this.submitview.setState({
            submitted:false
          });
          return;
        }
      }
    }
    this.sendData(options);

  }
*/

/*
  compareEmails = (email) => {
    this.showLog("[compareEmails] email: " + email);
    var ret = true;
    for (var i=0; i < this.state.emaillist.length; i++) {
      let item = this.state.emaillist[i];
      let currEmail = item.email;
      this.showLog("currEmail is " + currEmail);
      if (email.toLowerCase() === currEmail.toLowerCase()) {
        ret = false;
      }
    }

    return ret;
  }

  getTodaysDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = mm + '-' + dd + '-' + yyyy;
    return today;
  }
*/

/*
  sendData(options) {
    this.showLog('[sendData]')
    const ApiUrl = "https://33lm0xpgd0.execute-api.us-east-1.amazonaws.com/prod/service";
    let json = {
      "name": this.state.name,
      "email": this.state.email,
      "options": options,
      "date": this.getTodaysDate(),
    }

    return fetch(ApiUrl, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json)
    })
    .then((response) => response.json())

    .then((responseJson) => {
      this.showLog('responseJson: ' + JSON.stringify(responseJson));
      const body = responseJson["body"];
      this.showLog('body: ' + body);
      this.showLog('messagejson: ' + JSON.stringify(body));
      this.showAlert("Success", "You have been successfully entered.", "success");
    })
    .catch((error) => {
      this.showAlert("Error", JSON.stringify(error), "error");
    });
  }
*/

  showAlert(title, message, type) {

    if (type === "success") {
      swal(title,  message, type)
      .then((value) => {
          window.location.reload();
      });
    } else {
      swal(title, message, type);
    }
  }

  updateOptions(json) {
    this.showLog('[updateOptions] json: ' + JSON.stringify(json));
    this.setState({
      options: json
    }, () =>
      this.showLog('updated')
    )
  }

  getEventData = id => {
    for (var i in this.state.events) {
      let currevent = this.state.events[i];
      if (currevent.id == id) {
        return currevent;
      }
    }
  }

  render() {

    const authProps = {
       //eventsListAPI: "https://ys463i53i1.execute-api.us-east-1.amazonaws.com/dev/service",
       isAuthenticated: this.state.isAuthenticated,
       setAuthStatus: this.setAuthStatus,
       showAlert: this.showAlert,
       setCurrEventID: this.setCurrEventID,
       currEventId: this.state.currEventId,
       setCurrEventData: this.setCurrEventData,
       events: this.state.events,
       getEventData: this.getEventData,
       getEventsList: this.getEventsList,
       currEventData: this.state.currEventData,
       currEnvironment: this.state.currEnvironment,
       DevEventListAPI:this.state.DevEventListAPI,
       ProdEventListAPI:this.state.ProdEventListAPI,
       DevEventWebformAPI:this.state.DevEventWebformAPI,
       ProdEventWebformAPI:this.state.ProdEventWebformAPI
    }

    return (
      <div className="App">
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path="/" render={(props) => <LogIn {...props}  auth={authProps}/>} />
            <Route exact path="/home" render={(props) => <Home {...props}  auth={authProps}/>} />
            <Route exact path="/form" render={(props) => <Form {...props}  auth={authProps}/>} />
          </Switch>
        </div>
      </BrowserRouter>
      </div>
    );
  }
}
export default App;

/* <Route path="goto/:id" component={GoTo}/> */
