import React, { Component } from 'react';
import Entries from './Entries';
import Options from './Options';
import Submit from './Submit';
import Footer from './Footer';
import swal from 'sweetalert';
import Amplify from 'aws-amplify';
import aws_exports from '../aws-exports';
import logo from '../assets/RCMCertifiedProgram_Generic_WHITE.svg';
import "./Form.css";
import Cookies from 'js-cookie';

class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
      eventname:"event name",
      eventlocation: "event location",
      email: '',
      name: '',
      lastname:'',
      phone: '',
      schoolAffiliation:'',
      teachingRCM: 'Currently teaching RCM?',
      location:'',
      instruments:'',
      experience:'Please tell us how long you have been a music teacher',
      options: [],
      emaillist:[],
      sightreading: true,
      showSightReading: false,
      showProducts: false,
      instruction: 'loading',
      prizes: ["loading"],
    };
  }

  componentDidMount() {
    document.title = 'RCM Event'

    if (this.props.auth.isAuthenticated === false) {
      /*
      this.props.history.push("/home");
      return;
      */
      this.getExistingEmails();
      this.getText();
    } else {
      this.getExistingEmails();
      this.getText();
    }
  }

  showLog = text => {
    console.log(text);
  }

  resetForm = async () => {
    this.showLog("[resetForm]")
    this.setState({
      email: '',
      name: '',
      lastname:'',
      phone:'',
      instruments:'',
      schoolAffiliation:'',
      teachingRCM: "Currently teaching RCM?",
      experience:'Please tell us how long you have been a music teacher',
      location:'',
      options: [],
    });
    this.submitview.setState({
      submitted: false,
      checked:false
    }, () => {
      if (this.optionsview) {
        this.optionsview.resetOptions();
      }
    });
  }

  getText() {
    this.showLog("[getText]");
    const data = JSON.parse(Cookies.get('currEventData'));
    this.showLog("data: " + JSON.stringify(data, null, 4));
    this.showLog("id: " + data.id);

    this.setState({
      eventname: data.name,
      eventlocation: data.location
    });

    // GET DATA FOR WEBFORM =========
    const ApiUrl = (this.props.auth.currEnvironment == "prod") ? this.props.auth.ProdEventWebformAPI + "?id=" + data.id : this.props.auth.DevEventWebformAPI + "?id=" + data.id;
    // ==============================

    console.log("ApiUrl: " + ApiUrl)


    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json())

    .then((responseJson) => {
      this.showLog('responseJson: ' + JSON.stringify(responseJson));
      const body = responseJson["body"];
      let items = body.Items;
      for (var i = 0; i<items.length; i++) {
        let item = items[i];
        this.showLog('item: ' + JSON.stringify(item, null, 4));

        if (item.id === "1000") {
          this.showLog("form data: " + JSON.stringify(item, null, 4))
          if (item.showProducts) {
            this.setState({
              showProducts: item.showProducts
            });
          }

          if (item.sightreading) {
            this.setState({
              showSightReading: item.sightreading
            })
          }
        }

        if (item.instruction) {
          this.setState({
            instruction: item.instruction,
          }, () => {
            this.showLog("instruction text set");
          });
        }

        if (item.prizes) {
          this.setState({
            prizes: item.prizes,
          }, () => {
            this.showLog("prizes array set");
          });
        }
      }

      this.setState({
        //emaillist: list,
      }, () => {

      });


    })
    .catch((error) => {
      this.showLog("ERROR: " + JSON.stringify(error))
      //this.showAlert("Error", JSON.stringify(error), "error");
    });

  }

  goSubmit() {
    this.showLog('[goSubmit] options: ' + this.state.options);
    this.prepareData(this.state.options);
  }

  prepareData(json) {
    this.showLog('[prepareData]')

    var options = []
    for (var i = 0; i<json.length; i++) {
      let product = json[i];

      if (product.selected) {
        this.showLog('adding product: ' + product.title);
        options.push(product.title);
      }
    }
    this.validateEntries(options);
  }

  emailIsValid (email) {
    this.showLog("[emailIsValid] email: " + email);
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  validateEntries(options) {
    this.showLog('[validateEntries]' );
    if (this.state.name === '') {
      this.props.auth.showAlert("Oops", "Please enter your first name.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.lastname === '') {
      this.props.auth.showAlert("Oops", "Please enter your last name.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.email === '') {
      this.props.auth.showAlert("Oops", "Please enter your email address.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.location === '') {
      this.props.auth.showAlert("Oops", "Please tell us where you are located.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.phone === '') {
      this.props.auth.showAlert("Oops", "Please enter your phone.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.teachingRCM !== 'Yes' && this.state.teachingRCM !== 'No') {
      this.props.auth.showAlert("Oops", "Are you currently teaching RCM?", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.instruments === '') {
      this.props.auth.showAlert("Oops", "Please enter instruments you play.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;
    } else if (this.state.experience === 'Please tell us how long you have been a music teacher') {
      this.props.auth.showAlert("Oops", "Please tell us how long you have been a music teacher.", "warning");
      this.submitview.setState({
        submitted:false
      });
      return;

    } else if (this.state.email !== '') {
      if (this.emailIsValid(this.state.email) === false) {
        this.props.auth.showAlert("Oops", "Please enter your valid email address.", "warning");
        this.submitview.setState({
          submitted:false
        });
        return;
      } else {
        if (this.compareEmails(this.state.email) === false) {
          this.props.auth.showAlert("Oops", "You have already entered this email address.", "warning");
          this.submitview.setState({
            submitted:false
          });
          return;
        }
      }
    }
    this.sendData(options);

  }

  compareEmails = (email) => {
    this.showLog("[compareEmails] email: " + email);

    var ret = true;
    for (var i=0; i < this.state.emaillist.length; i++) {
      let item = this.state.emaillist[i];
      let currEmail = item.email;
      this.showLog("currEmail is " + currEmail);
      if (email.toLowerCase() === currEmail.toLowerCase()) {
        ret = false;
      }
    }

    return ret;
  }

  getExistingEmails = () => {
    this.showLog("[getExistingEmails]");

    const data = JSON.parse(Cookies.get("currEventData"));
    const ApiUrl = (this.props.auth.currEnvironment == "prod") ? this.props.auth.ProdEventWebformAPI + "?id=" + data.id : this.props.auth.DevEventWebformAPI + "?id=" + data.id;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //this.showLog('responseJson: ' + JSON.stringify(responseJson));
      const body = responseJson["body"];
      //this.showLog('body: ' + body.Items);
      //this.showLog('messagejson: ' + JSON.stringify(body.Items));
      //this.showAlert("Success", "You have been successfully entered.", "success");
      let list = body.Items;
      this.showLog('emailList: ' + JSON.stringify(list, null, 4));
      var emailList = [];

      for (var i in list) {
        let item = list[i];
        this.showLog("item: " + JSON.stringify(item.id, null, 4));
        if (item.id != "1000") {
          emailList.push(item);
        }
      }
      this.setState({
        emaillist: emailList,
      }, () => {
      // TESTIMNG ======
        //this.compareEmails("tak.yamada@rcmusic.ca");
      // ===============
      });


    })
    .catch((error) => {
      this.showLog("ERROR: " + JSON.stringify(error))
      //this.showAlert("Error", JSON.stringify(error), "error");
    });
  }

  getTodaysDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = mm + '-' + dd + '-' + yyyy;
    return today;
  }

  sendData(options) {
    this.showLog('[sendData] this.props.auth.currEnvironment: ' + this.props.auth.currEnvironment);

    const data = JSON.parse(Cookies.get('currEventData'));

    let json = {
      "name": this.state.name,
      "lastname": this.state.lastname,
      "email": this.state.email,
      "phone": this.state.phone,
      "instruments": this.state.instruments,
      "experience": this.state.experience,
      "location": this.state.location,
      "sightreading": this.state.sightreading,
      "schoolAffiliation":this.state.schoolAffiliation,
      "teachingRCM": this.state.teachingRCM,
      "options": options,
      "date": this.getTodaysDate(),
      "tableid":data.id,
      "authorization": data.authorizationkey,
      "templateid": data.templateid
      }

    this.showLog("sending: \n "+ JSON.stringify(json, null, 4));

    //TEMP ========
    //return
    //=============

    const ApiUrl = (this.props.auth.currEnvironment == "prod") ? this.props.auth.ProdEventWebformAPI : this.props.auth.DevEventWebformAPI;

    return fetch(ApiUrl, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json)
    })
    .then((response) => response.json())

    .then((responseJson) => {
      this.showLog('responseJson: ' + JSON.stringify(responseJson));
      if (responseJson.errorType === "Error" || responseJson.errorType === "Runtime.ExitError") {
        this.showAlert("Error", responseJson.errorMessage, "error");
      } else {
        const body = responseJson["body"];
        this.showLog('body: ' + body);
        this.showLog('messagejson: ' + JSON.stringify(body));
        this.showAlert("Success", "You have been successfully entered.", "success");
        this.state.emaillist.push(json);
        this.setState({
          emaillist: this.state.emaillist,
        }, () => {
        // TESTIMNG ======
          //this.compareEmails("tak.yamada@rcmusic.ca");
        // ===============
        });
      }
    })
    .catch((error) => {
      this.showAlert("Error", JSON.stringify(error), "error");
    });

  }

  showAlert(title, message, type) {

    if (type === "success") {
      swal(title,  message, type)
      .then((value) => {
          //window.location.reload();
          this.resetForm();
      });
    } else {
      swal(title, message, type);
    }
  }

  updateOptions(json) {
    this.showLog('[updateOptions] json: ' + JSON.stringify(json));
    this.setState({
      options: json
    }, () =>
      this.showLog('updated')
    )
  }

  render() {
    return (
      <div className='App'>
        <header className="App-header">

          <img src={logo} className="App-logo" alt="logo" />
        </header>


        <div className="event-name"> {this.state.eventname} - {this.state.eventlocation}</div>
        <Entries mainView={this} />

          {(this.state.showProducts === true) &&
            <Options ref={ optionsview => {this.optionsview = optionsview; }} mainView={this} />
          }

        <Submit ref={ submitview => {this.submitview = submitview; }} mainView={this} />
        <Footer mainView={this} />

      </div>
    );
  }
}

/*



*/

export default Form;
