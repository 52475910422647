import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import LandingPageHeader from "./LandingPageHeader";
import "./Home.css";
class Home extends Component {

  state = {
    eventdata:null,
    events: [],
    eventname: "",
    eventlocation: "",
    eventdate: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  showLog = text => {
    //console.log(text);
  }

  componentDidMount() {
    this.showLog("[componentDidMount] Home");
    if (this.props.auth.isAuthenticated === false) {

      this.props.history.push("/");
      return;

    } else {
      //this.showLog("username: " + JSON.stringify(this.props.auth.user.attributes, null, 4));
      this.showLog("this.state.events: " + this.state.events);
      if (this.state.events.length < 1) {
        this.props.auth.getEventsList();
        //this.getEventsList();
      }
    }
  }

  getEventsList = () => {
    this.showLog("[getEventsList] Home");

    this.setState({
      errors: {
        apierrors: "retrieving the events list..."
      }
    });
    // get all the events from the database

    const ApiUrl = (this.props.auth.currEnvironment == "prod") ? this.props.auth.ProdEventListAPI : this.props.auth.DevEventListAPI;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //this.showLog("responseJson: " + JSON.stringify(responseJson, null, 4));
      const body = responseJson["body"];
      //this.showLog("body: " + JSON.stringify(body, null, 4));
      const items = body.Items;
      this.showLog("items: " + JSON.stringify(items, null, 4));

      this.setState({
        events: items,
        errors: {
          apierrors: ""
        }


      }, () => {
        //this.updateEventsList();
        this.showLog("events set");
        return;
      });


      if (items === undefined) {
        this.setState({
          errors: {
            apierrors: "can't find events."
          }
        });
        return;
      }

    })
    .catch((error) => {
      this.showLog("ERROR [getEventsList]: " + JSON.stringify(error));
      this.setState({
        errors: {
          apierrors: JSON.stringify(error)
        }
      });
      return;
    });
  }

  updateEventsList = () => {
    //this.showLog("[updateEventsList] events: " + JSON.stringify(this.state.events, null, 4));

    /*
    if (this.state.events == []) {
      return;
    }
    */
    if (this.props.auth.events == []) {
      return;
    }

    /*
    var events = this.state.events.map((item, index) => (
       <Row key={index} id={item.id}>
       <Col><div className="event-entry">{item.name}</div></Col>
       <Col><div className="event-entry">{item.location}</div></Col>
       <Col><div className="event-entry">{item.date}</div></Col>
       <Col>
          <div className="button-entry"><Button id={item.id} className="landing-button-alt button-small " onClick={ this.handleSelectEvent }>Select</Button></div>
        </Col>
       </Row>
    ))
    */
    var events = this.props.auth.events.map((item, index) => (
       <Row key={index} id={item.id}>
       <Col><div className="event-entry">{item.name}</div></Col>
       <Col><div className="event-entry">{item.location}</div></Col>
       <Col><div className="event-entry">{item.date}</div></Col>
       <Col>
          <div className="button-entry"><Button id={item.id} className="landing-button-alt button-small " onClick={ this.handleSelectEvent }>Select</Button></div>
        </Col>
       </Row>
    ))
    return (
      <div>
        {events}
      </div>
    );

  }

  handleSelectEvent = async event => {
    this.showLog("[handleSelectEvent]");
    let id = event.target.id;
    this.showLog("id: " + id);
    event.preventDefault();

    //this.showLog("eventsListAPI: " + this.props.auth.eventsListAPI);
    let currevent = this.props.auth.getEventData(id);
    //let currevent = this.getEventData(id);
    this.showLog("currevent: " + JSON.stringify(currevent, null, 4));

    //await this.props.auth.setCurrEventID(id);
    await this.props.auth.setCurrEventData(currevent);

    this.props.history.push("/form");

  }

  getEventData = id => {
    for (var i in this.state.events) {
      let currevent = this.state.events[i];
      if (currevent.id == id) {
        return currevent;
      }
    }
  }


  render() {
    return (
      <div className="container">
        <LandingPageHeader />
        <div className="landing-main">
          <div className="landing-title">Current Events List</div>
        </div>
        <Container className="form-container">
          <Row>
            <Col><div className="event-entry-title">name</div></Col>
            <Col><div className="event-entry-title">location</div></Col>
            <Col><div className="event-entry-title">date</div></Col>
            <Col><div className="event-entry-title"></div></Col>
          </Row>
          {this.updateEventsList()}
        </Container>
        <Container className="form-container">
          <Row  className="justify-content-md-center">
            <Col className="form-field text-center">
              {this.state.errors.apierrors}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
