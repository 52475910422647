import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, InputGroup, FormControl, Dropdown, DropdownButton } from 'react-bootstrap';
import './Entries.css';
import './raffle_style_v1.css';


export default class Entries extends Component {

  componentDidMount() {
    this.showLog("[componentDidMount] Entries  \n this.props.mainView.state.instruction: " + this.props.mainView.state.instruction);
  }

  showLog = text => {
    //console.log(text);
  }

  handleChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.mainView.setState({
      [name]: value,
    });
  }

  handleDropdown(e) {
    this.props.mainView.setState({
      experience: e,
    });
  }

  handleDropdownTeachingRCM(e) {
    this.props.mainView.setState({
      teachingRCM: e,
    });
  }



  render() {
    return (
      <div className='Entries-container'>
        <div className="title">Ballot Form</div>
        <div className='instruction'>
        <p> {this.props.mainView.state.instruction} </p>
        </div>
        <div className='instruction-subtitle'>
        <p>
        Giveaway prizes:
        </p>
        </div>

        {this.props.mainView.state.prizes && this.props.mainView.state.prizes.map((value, index) => {
          let date = value.date;
          let prize = value.prize;
          return <div key={index} className='instruction-list'><b>{date}:</b> {prize}</div>
        })}

        <Container fluid='true'>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">FIRST NAME:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="Enter your first name"
                  name="name"
                  value={this.props.mainView.state.name}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">LAST NAME:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="Enter your last name"
                  name="lastname"
                  value={this.props.mainView.state.lastname}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">EMAIL:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="Enter your email"
                  name="email"
                  value={this.props.mainView.state.email}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">LOCATION:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="Enter State/Province/Territory"
                  name="location"
                  value={this.props.mainView.state.location}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">PHONE:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="Enter your phone number"
                  name="phone"
                  value={this.props.mainView.state.phone}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">AFFILIATION:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="School affiliation (if applicable)"
                  name="schoolAffiliation"
                  value={this.props.mainView.state.schoolAffiliation}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">TEACHING RCM:</div></Col>
              <Col xs={10}>
                <InputGroup>
                  <DropdownButton
                    id="experience-dropdown"
                    title={this.props.mainView.state.teachingRCM}
                    onSelect={(event) =>this.handleDropdownTeachingRCM(event)}
                  >
                    <Dropdown.Item eventKey="Yes">Yes</Dropdown.Item>
                    <Dropdown.Item eventKey="No">No</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">INSTRUMENT:</div></Col>
              <Col xs={10}>
                <InputGroup>
                <FormControl
                  className='textfield'
                  placeholder="Enter your principle instrument(s)"
                  name="instruments"
                  value={this.props.mainView.state.instruments}
                  onChange={(event)=>this.handleChange(event)}
                  />
                  </InputGroup>
            </Col>
          </Row>
          <Row className='Entry'>
              <Col className='vertical-center'><div className="label">EXPERIENCE:</div></Col>
              <Col xs={10}>
                <InputGroup>
                  <DropdownButton
                    id="experience-dropdown"
                    title={this.props.mainView.state.experience}
                    onSelect={(event) =>this.handleDropdown(event)}
                  >
                    <Dropdown.Item eventKey="Less than 1 year">Less than 1 year</Dropdown.Item>
                    <Dropdown.Item eventKey="1-5 years">1-5 years</Dropdown.Item>
                    <Dropdown.Item eventKey="More than 6 years">More than 6 years</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
