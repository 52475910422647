import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './raffle_style_v3.css';

export default class Footer extends Component {


  render() {
    return(
      <div className ="Footer-Container">
        <p>The RCM Certificate Program is provided by The Royal Conservatory of Music in Canada and RCM America Inc in the US.</p>
      </div>
    );
  }
}
