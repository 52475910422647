import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import "./LogIn.css";
import LandingPageHeader from "./LandingPageHeader";

class LogIn extends Component {

  constructor(props) {
    super(props);

  }
  showLog = text => {
    //console.log(text);
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const search = url.search;
    //this.showLog("search: " + search);
    if (search) {
      //this.showLog("url: " + url + " search: " + search);
      var searchParams = new URLSearchParams(search);
      const goto = searchParams.getAll("goto");
      this.showLog("goto: " + goto);
      this.goTo(goto);
    } else {
      this.setState({
        showLogIn:true
      });
    }
  }

  state = {
    showLogIn: false,
    canLogin:true,
    profile:null,
    username: "",
    password: "",
    id:"",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  goTo = async (id) => {
    this.showLog("[goTo] id: " + id + " history " + this.props.history);
    await this.props.auth.getEventsList();
    let currevent = this.props.auth.getEventData(id);
    this.showLog("currevent: " + JSON.stringify(currevent, null, 4));
    await this.props.auth.setCurrEventData(currevent);

    this.props.history.push("/form");
  }

  allowEnterSite = () => {
    this.showLog("[allowEnterSite]");
    this.props.history.push("/home");
    return;
  }

  onInputChange = event => {
    if (event.target.id === "username") {
      event.target.value = event.target.value.toString().toLowerCase();
    }
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  resetLogIn = () => {
    this.setState({
      canLogin: true
    });
  }

  checkUserProfile = () => {
    this.showLog("[checkUserProfile] username: " + this.state.username);
    this.setState({
      canLogin: false,
      errors: {
        apierrors: "checking the user ID..."
      }
    });
    const ApiUrl = "https://in2btjyts8.execute-api.us-east-1.amazonaws.com/dev/service?id="+this.state.username;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //this.showLog("responseJson: " + JSON.stringify(responseJson, null, 4));

      const body = responseJson["body"];
      const item = body.Item;
      //this.showLog("item: " + JSON.stringify(item, null, 4));
      const userpass = item.pass;

      const pass = this.state.password;


      if (pass.length < 2) {
        this.setState({
          canLogin: false,
          errors: {
            apierrors: "Please enter your correct password"
          }
        });
        return;
      }

      if (userpass != pass) {
        this.setState({
          canLogin: false,
          errors: {
            apierrors: "wrong password"
          }
        });
        return;
      }

      //console.this.showLog("userpass: " + userpass);


      if (item === undefined) {
        this.setState({
          canLogin: false,
          errors: {
            apierrors: "this user doesn't exist."
          }
        });
        return;
      } else {
        this.setState({
          canLogin: true
        });
      }
    })
    .catch((error) => {
      //this.showLog("ERROR [checkUserProfile]: " + JSON.stringify(error));
      this.setState({
        canLogin: false,
        errors: {
          apierrors: JSON.stringify(error)
        }
      });
      return;
    });
  }

  handleSubmit = async event => {
    //this.showLog("[handleSubmit]");


    event.preventDefault();

    await this.resetLogIn();

    // check if the user ID is an email
    if (!this.state.username.includes("@")) {
      this.showLog("email doesn't include @");
      this.setState({
        canLogin: false,
        errors: {
          apierrors: "Please enter a valid email address."
        }
      });
      return;
    } else {
      this.setState({
        canLogin: true,
      });
    }

    if (!this.state.canLogin) {
      return;
    }


    await this.checkUserProfile();

    // Form validation

    if (!this.state.canLogin) {
      return;
    }



    if (!this.state.canLogin) {
      return;
    }

    this.clearErrorState();

    this.props.auth.setAuthStatus(true);

    this.allowEnterSite();

  };


  render() {
    return (
        <div className="container">

          {this.state.showLogIn && (
            <div>
            <LandingPageHeader />
            <div className="landing-main">
              <div className="landing-title">Event Host Log In</div>
            </div>
          <Container className="form-container">
            <Row  className="justify-content-md-center">
              <Col className="form-field text-center">
                {this.state.errors.apierrors}
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">E-mail</div>
                  <input
                    className="form-input"
                    type="text"
                    id="username"
                    aria-describedby="emailHelp"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">Password</div>
                    <input
                      className="form-input"
                      type="password"
                      id="password"
                      value={this.state.password}
                      onChange={this.onInputChange}
                    />
                </div>
              </Col>
            </Row>

            <Row>
                <Col className="form-field">
                  <Container className="register-buttons-area">
                    <Row className="small-container justify-content-md-center">
                      <Col className="field-left form-field input-small">
                        <div className="field-area input-small">
                          <div>
                            <Button id="login" className="button-left landing-button register-button" onClick={ this.handleSubmit }>Log in</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="spacer-bottom" />
                      </Col>
                    </Row>
                  </Container>
                </Col>
            </Row>
          </Container>
          </div>
          )}
        </div>
    );
  }

}

export default LogIn;
