import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Submit.css';
import './raffle_style_v3.css';
import swal from 'sweetalert';

export default class Submit extends Component {

  constructor(props) {
    super(props);


    this.state = {
      checked:true,
      submitted: false,
      sightreading: true
    };
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
    this.handleSightReadingChange = this.handleSightReadingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showLog = text => {
    //console.log(text);
  }


  showAlert(title, message, type) {

    if (type === "success") {
      swal(title, 'message: ' + message, type)
      .then((value) => {
          window.location.reload();
      });
    } else {
      swal(title, message, type);
    }
  }

  handleSubmit(event) {
    this.showLog('[handleSubmit]');
    event.preventDefault();

    if (this.state.checked === false) {
      this.showAlert("Oops", "Please agree to the terms.", "warning");
    } else {
      this.setState({
        submitted: true,
        }, () =>
        this.props.mainView.goSubmit()
      );
    }

    //this.handleTags();
  };

  handleAgreeChange(evt) {
     if(this.state.checked !== evt.target.checked) {
        this.setState({
          checked:evt.target.checked
        });
     }
  }

  handleSightReadingChange(evt) {
     if(this.props.mainView.state.sightreading !== evt.target.checked) {
        this.props.mainView.setState({
          sightreading:evt.target.checked
        });
     }
  }

  render() {

    var divStyle = {
      display:this.state.submitted?'none':'block'
    };

    return(
      <div className="Submit-Container">
        <form className="form" onSubmit={this.handleSubmit}>
          <div className='field-item'>
            <div className='submit-button' style={divStyle} onClick={(event) => this.handleSubmit(event)}>Submit</div>
            <div className="agreeCheck">
              <input type="checkbox" checked={this.state.checked} onChange={this.handleAgreeChange}/>
               I agree to receive email updates and communication from the RCM. I can unsubscribe at any time.
            </div>

            {/* this.props.mainView.state.showSightReading &&
              <div className="agreeCheck">
                <input type="checkbox" checked={this.props.mainView.state.sightreading} onChange={this.handleSightReadingChange}/>
                I agree to receive credentials for the Sight Reading course.
              </div>
            */}

          </div>
        </form>
      </div>
    );
  }
}
