import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './ListItem.css';
import './raffle_style_v1.css';

export default class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  showLog = text => {
    //console.log(text);
  }

  updateList() {
    this.showLog("[updateList] name: " + this.props.name + " id: " + this.props.id + " slected: " + this.state.selected);
    this.props.listView.updateResults(this.props.name, this.props.id, this.props.description, this.state.selected);
  }

  toggleItem(event) {
    this.showLog('[toggleItem]')

    this.setState({
      selected: (this.state.selected) ? false : true
        }, () =>
        this.updateList()
    );
  }

  render() {
    return(
      <div className='Item-Container'>
        <Container>
          <Row>
            <Col className='item-name vertical-center'> {this.props.name} </Col>
            <Col className='item-category vertical-center'> {this.props.description} </Col>
            <Col xs lg="2">
              <Button className="button" type="button" onClick={e => this.toggleItem(e)} variant={(this.state.selected) ? "primary" : "outline-primary"}>{(this.state.selected) ? "Selected" : "Select"}</Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
