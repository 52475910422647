import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './OptionsList.css';
import ListItem from './ListItem';
import './raffle_style_v1.css';

export default class Options extends Component {

  constructor(props) {
    super(props);

  }

  componentWillMount() {
    this.showLog('[ComponentDidMount] in OptionsList and ItemList: ' + JSON.stringify(this.props.mainView.state.options, null, 4));
  }

  showLog = text => {
    //console.log(text);
  }

  updateResults(name, id, description, bool) {
    this.showLog('[updateResults] name: ' +  name +  ' id: ' + id + " description: " + description + " bool: " + bool + " this.props.mainView.state.options: " + this.props.mainView.state.options);
    var updatedOptions = [];
    for (var i = 0; i<this.props.mainView.state.options.length; i++) {
      var currProduct = this.props.mainView.state.options[i];
      this.showLog('currProduct: ' + currProduct.title);
      if (name === currProduct.title) {
          this.showLog("update " + name)
          let updatedProduct = {"title": name, "selected" : bool, "description": description};
          updatedOptions.push(updatedProduct);
      } else {
        updatedOptions.push(currProduct);
      }
    }

    this.showLog('updatedOptions: ' + updatedOptions);
    this.setState({
      options: updatedOptions
        }, () =>
        this.props.mainView.updateOptions(updatedOptions)
    );
  }

  getItems() {
    this.showLog('[getItems] options: ' + JSON.stringify(this.props.mainView.state.options, null, 4));

    let json = this.props.mainView.state.options;
    var list =[];
    //var currID = 0;
    for (var i = 0; i<json.length; i++) {
      var item = [];
      let product = json[i];
      let title = product.title;
      let description = product.description;
      item.push(title);
      item.push(description);
      item.push(i);
      list.push(item);
    };

    let itemList = list.map((item, index) =>

      <ListItem key={index} name={item[0]} description={item[1]} id={item[2]} listView={this} />
      //<div key={index}>{item[0]}</div>
    );

    return (
      <div className="List-container">{itemList}</div>
    );
  }

  render() {
    //this.showLog('before calling getItems: options: mainView' + this.props.mainView.state.options);
    return(
      <div>
        {this.props.mainView.state.options && this.getItems()}
      </div>
    );
  }
}
